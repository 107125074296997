/* ==========================================================================
   Variables
   ========================================================================== */
/* ==========================================================================
   Helpers
   ========================================================================== */
.clear {
  margin: 0px;
  padding: 0px;
  font-size: 0px;
  line-height: 0px;
  height: 1px;
  clear: both;
  float: none;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
/* ==========================================================================
   Responsive settings
   ========================================================================== */
@media screen and (min-width: 1025px) {
  .desktop-block {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .desktop-block {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .desktop-block {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .tablet-block {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tablet-block {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .tablet-block {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .mobile-block {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mobile-block {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .mobile-block {
    display: block;
  }
}
@-ms-viewport {
  width: device-width;
  initial-scale: 1;
}
@-o-viewport {
  width: device-width;
  initial-scale: 1;
}
@viewport {
  width: device-width;
  initial-scale: 1;
}
/* ==========================================================================
   Base
   ========================================================================== */
* {
  box-sizing: border-box;
  outline: none;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  color: #0D3F7A;
}
p {
  margin: 25px 0px;
  padding: 0px;
}
hr {
  display: block;
  height: 1px;
  border: 0 none;
  border-top: 1px solid #ccc;
  margin: 0px;
  padding: 0px;
}
input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}
button {
  background: none;
  border: none;
  border-spacing: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/* ==========================================================================
   Skeleton
   ========================================================================== */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 14px;
  background-color: #ffffff;
  color: #000;
  /*
    background-image: url(../images/pattern.png);
    background-repeat: repeat;
    background-position: 0 0;

    .tablet ({
        background-image: url(../images/pattern_regular@2x.png);
        background-size: contain;
    });
    .mobile ({
        background-image: url(../images/pattern_regular@2x.png);
        background-size: contain;
    });
    .retina ({
        background-image: url(../images/pattern_regular@2x.png);
        background-size: contain;
    });
    */
}
.center {
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  height: 100%;
}
.logo {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  margin: 5px 0;
}
.icon-logo {
  display: inline-block;
  fill: #fff;
  width: 55px;
  height: 50px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .icon-logo {
    width: 45px;
    height: 40px;
  }
}
@media screen and (max-width: 767px) {
  .icon-logo {
    width: 35px;
    height: 30px;
  }
}
@media screen and (min-width: 1025px) {
  .container-desktop {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container-desktop {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .container-desktop {
    display: none;
  }
}
.container-mobile {
  font-size: 14px;
}
@media screen and (min-width: 1025px) {
  .container-mobile {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container-mobile {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .container-mobile {
    display: block;
  }
}
.content {
  padding: 80px 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .content {
    padding: 70px 0;
  }
}
@media screen and (max-width: 767px) {
  .content {
    padding: 60px 0;
  }
}
/* ==========================================================================
   Links
   ========================================================================== */
.link {
  background: none;
  border: none;
  border-spacing: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}
/* ==========================================================================
   Page property
   ========================================================================== */
.page-detail #fp-nav {
  display: none;
}
/* ==========================================================================
   Header
   ========================================================================== */
.header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /*
    .desktop ({
        left: 50%;
        transform: translate(-50%,0);
    });
    */
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header {
    height: 70px;
  }
}
@media screen and (max-width: 767px) {
  .header {
    height: 60px;
  }
}
.header-line {
  width: 100%;
  height: 60px;
  background-color: #08a0ad;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header-line {
    background-image: none;
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .header-line {
    background-image: none;
    height: 40px;
  }
}
.header-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.header-title {
  color: #fff;
}
.header-title h1 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  display: inline-block;
  vertical-align: middle;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header-title h1 {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .header-title h1 {
    font-size: 20px;
  }
}
.header-lang {
  color: #fff;
  display: inline-block;
  font-size: 22px;
  font-weight: 100;
  margin-left: 45px;
  text-transform: uppercase;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header-lang {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .header-lang {
    font-size: 14px;
  }
}
/* ==========================================================================
   Grid
   ========================================================================== */
.grid {
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  margin: 0 auto;
}
.grid .img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.grid-main {
  width: 1870px;
  height: 1195px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
.grid-main .cols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.grid-main .col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.grid-main .cells {
  display: flex;
  justify-content: space-between;
}
.grid-main .cell {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.grid-main .cell-empty {
  background: rgba(224, 250, 252, 0.6);
}
.grid-main .cell-empty:before {
  content: "";
  display: block;
  position: absolute;
  width: 30%;
  height: 30%;
  background-image: url(../images/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}
.cell-1 {
  width: 720px;
  height: 385px;
}
.cell-2 {
  width: 1130px;
  height: 385px;
}
.cell-3 {
  width: 1390px;
  height: 385px;
}
.cell-4 {
  width: 685px;
  height: 385px;
}
.cell-5 {
  width: 685px;
  height: 385px;
}
.cell-6 {
  width: 460px;
  height: 790px;
}
.grid-detail {
  width: 1830px;
  height: 1130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.grid-detail .cols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
}
.grid-detail .col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
.grid-detail .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.grid-detail .cell {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.grid-detail .cell-detail {
  background: rgba(224, 250, 252, 0.6);
}
.cell-detail-1 {
  width: 1045px;
  height: 585px;
}
.cell-detail-2 {
  width: 1045px;
  height: 520px;
}
.cell-detail-3 {
  width: 372px;
  height: 480px;
}
.cell-detail-4 {
  width: 372px;
  height: 480px;
}
.cell-detail-5 {
  width: 765px;
  height: 630px;
}
/* ==========================================================================
   Common
   ========================================================================== */
.detail-video {
  position: relative;
  cursor: pointer;
}
.detail-video.active .detail-video-icon,
.detail-video.active .detail-video-image {
  opacity: 0;
  display: none;
  z-index: -1;
}
.detail-video.active .detail-video-frame {
  opacity: 1;
  position: relative;
  z-index: 1;
}
.detail-video-icon {
  opacity: 1;
  display: block;
  /*
    .retina ({
        display: none;
    });
    */
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .detail-video-icon {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .detail-video-icon {
    display: none;
  }
}
.icon-video {
  display: inline-block;
  position: absolute;
  fill: #fff;
  opacity: 0.8;
  width: 20%;
  height: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.detail-video-image {
  font-size: 0;
  margin: 0;
  padding: 0;
  opacity: 1;
  z-index: 1;
  /*
    .retina ({
        display: none;
    });
    */
}
.detail-video-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .detail-video-image {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .detail-video-image {
    display: none;
  }
}
.ipad .detail-video-image,
.tablet .detail-video-image {
  display: none;
}
.detail-video-frame-hide {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  z-index: auto;
  opacity: 1;
  height: auto;
  width: 100%;
}
.detail-video-frame {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /*
    .retina ({
        .detail-video-frame-hide;
    });
    */
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .detail-video-frame {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    z-index: auto;
    opacity: 1;
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .detail-video-frame {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    z-index: auto;
    opacity: 1;
    height: auto;
    width: 100%;
  }
}
.detail-video-frame .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.detail-video-frame img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.ipad .detail-video-frame,
.tablet .detail-video-frame {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  z-index: auto;
  opacity: 1;
  height: auto;
  width: 100%;
}
.detail-data {
  background: #6cceff;
  background: #F2F2F2;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: normal;
  color: #fff;
  color: #333333;
  padding: 5%;
}
.detail-data .data-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.detail-data .data-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.6vh;
  line-height: 3.8vh;
  margin-bottom: 1.8vh;
}
.detail-data .data-text {
  font-size: 2.2vh;
  line-height: 2.2vh;
  margin-bottom: 3vh;
}
.detail-data .data-info {
  font-size: 0;
}
.detail-data .info-item {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  font-size: 2vh;
  line-height: 2vh;
  margin-bottom: 0.5vh;
  padding-right: 5%;
}
.detail-data .item-title {
  display: inline;
}
.detail-data .item-content {
  display: inline;
}
.project-item {
  display: block;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .project-item {
    height: 25vw;
  }
}
@media screen and (max-width: 767px) {
  .project-item {
    height: 40vw;
  }
}
.project-item:last-child {
  margin-bottom: 0;
}
.project-video {
  position: relative;
  cursor: pointer;
  margin-bottom: 2vw;
  border-radius: 15px;
  overflow: hidden;
}
.project-video .video-frame {
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
}
.project-video .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container-mobile .project-video .video iframe {
  width: 100%;
  height: 100%;
}
.project-data {
  background: #6cceff;
  background: #F2F2F2;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: normal;
  color: #fff;
  color: #333333;
  padding: 4%;
  margin-bottom: 2vw;
  border-radius: 15px;
}
.project-data .data-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 5%;
}
.project-data .data-title {
  font-weight: 700;
  font-size: 4vw;
  line-height: 3.8vw;
  margin-bottom: 2vw;
}
@media screen and (max-width: 767px) {
  .project-data .data-title {
    font-size: 18px;
    line-height: 20px;
  }
}
.project-data .data-text {
  font-size: 2.5vw;
  line-height: 2.5vw;
  margin-bottom: 3vw;
}
@media screen and (max-width: 767px) {
  .project-data .data-text {
    font-size: 14px;
    line-height: 16px;
  }
}
.project-data .data-info {
  font-size: 0;
}
.project-data .info-item {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  font-size: 2vw;
  line-height: 2vw;
  margin-bottom: 0.5vh;
  padding-right: 5%;
}
@media screen and (max-width: 767px) {
  .project-data .info-item {
    font-size: 14px;
    line-height: 16px;
  }
}
.project-data .item-title {
  display: inline;
}
.project-data .item-content {
  display: inline;
}
.project-images .images-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
.project-images .img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.project-images .images-row {
  margin-bottom: 2vw;
}
.project-images .images-row:last-child {
  margin-bottom: 0;
}
.project-images .images-cols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
}
.project-images .images-col {
  width: 50%;
  padding-left: 1vw;
  padding-right: 1vw;
}
.project-images .images-col:first-child {
  padding-left: 0;
}
.project-images .images-col:last-child {
  padding-right: 0;
}
.project-images .images-col .images-cell {
  width: 100%;
  height: 60vw;
}
.project-images .images-row .images-cell {
  width: 100%;
  height: 60vw;
}
.project-images .images-cell {
  border-radius: 15px;
  overflow: hidden;
}
.about {
  position: relative;
  width: 75vw;
  height: 98vh;
  padding: 80px 0;
  margin: 0 auto;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about {
    padding: 70px 0;
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .about {
    padding: 60px 0;
    width: 100%;
    height: auto;
  }
}
.about-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-wrapper {
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .about-wrapper {
    width: auto;
    height: auto;
  }
}
.about-hero {
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-hero {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .about-hero {
    display: none;
  }
}
.hero-parovozov {
  width: 635px;
  height: 591px;
  width: 25%;
  height: 50%;
  background-position: 0 0;
  background-image: url(../images/hero/parovozov.png);
  top: 0;
  left: 0;
}
.hero-hare {
  width: 118px;
  height: 186px;
  background-image: url(../images/hero/hare.png);
  left: 50%;
  top: 3%;
  transform: translate(-60%, 0);
  z-index: 0;
  width: 5%;
  height: 15%;
}
.hero-bird {
  width: 240px;
  height: 240px;
  background-image: url(../images/hero/bird.png);
  right: 10%;
  top: -1%;
  z-index: 3;
  width: 9%;
  height: 20%;
}
.hero-bear {
  width: 488px;
  height: 530px;
  background-image: url(../images/hero/bear.png);
  right: 5%;
  top: 50%;
  z-index: 0;
  transform: translate(0, -60%);
  width: 15%;
  height: 30%;
}
.hero-kids {
  width: 467px;
  height: 445px;
  background-position: 100% 100%;
  background-image: url(../images/hero/kids.png);
  right: 5%;
  bottom: -2%;
  z-index: 5;
  width: 15%;
  height: 30%;
}
.hero-elk {
  width: 462px;
  height: 787px;
  background-position: 0 100%;
  background-image: url(../images/hero/elk.png);
  bottom: -5%;
  left: 4%;
  z-index: 7;
  width: 15%;
  height: 55%;
}
.about-data {
  background-color: #6cceff;
  width: 70%;
  height: 70%;
  position: relative;
  z-index: 100;
  margin: 0 auto;
  border-radius: 15px;
  z-index: 1;
  border: 10px solid #6cceff;
  overflow-y: auto;
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-data {
    width: 95%;
    height: auto;
    border-width: 10px;
  }
}
@media screen and (max-width: 767px) {
  .about-data {
    width: 95%;
    height: auto;
    border-width: 10px;
  }
}
.about-data:after {
  content: "";
  background-image: url(../images/hero/elk-rtl.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 100%;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 35%;
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-data:after {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .about-data:after {
    display: block;
  }
}
.about-data .data-content {
  margin: 20px;
  padding: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-data .data-content {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .about-data .data-content {
    padding: 0;
  }
}
.about-data .data-text {
  margin-bottom: 4vw;
  font-size: 1.5vw;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-data .data-text {
    font-size: 3vw;
  }
}
@media screen and (max-width: 767px) {
  .about-data .data-text {
    font-size: 16px;
  }
}
.about-data .data-contacts {
  font-size: 1.5vw;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-data .data-contacts {
    font-size: 3vw;
  }
}
@media screen and (max-width: 767px) {
  .about-data .data-contacts {
    font-size: 16px;
  }
}
.about-data .contacts-title {
  margin-bottom: 1vw;
}
.about-data .contacts-content {
  display: table;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .about-data .contacts-content {
    width: auto;
    display: block;
  }
}
.about-data .contacts-address {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .about-data .contacts-address {
    margin-bottom: 1vw;
    width: auto;
    display: block;
  }
}
.about-data .contacts-call {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
.about-data .contacts-email a {
  color: #0D3F7A;
}
.about-data .contacts-email a:hover {
  color: #08274c;
}
/* ==========================================================================
   Footer
   ========================================================================== */
.footer {
  width: 100%;
  height: 80px;
  padding-top: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  /*
    .desktop ({
        left: 50%;
        transform: translate(-50%,0);
    });
    */
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .footer {
    height: 70px;
  }
}
@media screen and (max-width: 767px) {
  .footer {
    height: 60px;
  }
}
.footer-line {
  overflow: hidden;
  height: 60px;
  background-color: #08a0ad;
  background-position: 100% 0px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .footer-line {
    background-image: none;
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .footer-line {
    background-image: none;
    height: 40px;
  }
}
.footer-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.footer-logo {
  display: inline-block;
  vertical-align: middle;
  padding-right: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .footer-logo {
    padding-right: 40px;
  }
}
@media screen and (max-width: 767px) {
  .footer-logo {
    padding-right: 20px;
  }
}
.footer-links {
  display: inline-block;
  vertical-align: middle;
}
.footer-contacts {
  text-align: right;
}
.link-about {
  font-size: 18px;
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .link-about {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .link-about {
    font-size: 14px;
  }
}
.contacts {
  font-size: 16px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contacts {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .contacts {
    font-size: 12px;
  }
}
.contacts-phone {
  color: #fff;
}
.contacts-email a {
  color: #fff;
  font-weight: 700;
}
/* ==========================================================================
   Plugins
   ========================================================================== */
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #08A0AD;
}
