/* ==========================================================================
   Variables
   ========================================================================== */

@azure: #08a0ad;
@blue: #0D3F7A;

/* ==========================================================================
   Helpers
   ========================================================================== */

.clear {
    margin: 0px;
    padding: 0px;
    font-size: 0px;
    line-height: 0px;
    height: 1px;
    clear: both;
    float: none;
}

.clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

/* ==========================================================================
   Responsive settings
   ========================================================================== */

.retina(@rules) {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2) {
        @rules();
    }
}

.desktop(@rules) {
    @media screen and (min-width: 1025px) {@rules();}
}

.tablet(@rules) {
    @media screen and (min-width: 768px) and (max-width: 1024px) {@rules();}
}

.tablet-landscape(@rules) {
    @media screen and (min-width: 980px) and (max-width: 1024px) {@rules();}
}

.mobile(@rules) {
    @media screen and (max-width: 767px) {@rules();}
}

.desktop-block {
    .desktop ({
        display: block;
    });
    .tablet ({
        display: none;
    });
    .mobile ({
        display: none;
    });
}

.tablet-block {
    .desktop ({
        display: none;
    });
    .tablet ({
        display: block;
    });
    .mobile ({
        display: none;
    });
}

.mobile-block {
    .desktop ({
        display: none;
    });
    .tablet ({
        display: none;
    });
    .mobile ({
        display: block;
    });
}

@-ms-viewport {
    width: device-width;
    initial-scale: 1;
}
@-o-viewport {
    width: device-width;
    initial-scale: 1;
}
@viewport {
    width: device-width;
    initial-scale: 1;
}

/* ==========================================================================
   Base
   ========================================================================== */

* {
    box-sizing: border-box;
    outline: none;
}

a {
    text-decoration: none;
    color: #333;
}
a:hover {
    color: @blue;
}

p {
    margin: 25px 0px;
    padding: 0px;
}

hr {
    display: block;
    height: 1px;
    border: 0 none;
    border-top: 1px solid #ccc;
    margin: 0px;
    padding: 0px;
}

input,
textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

button {
    background: none;
    border: none;
    border-spacing: 0;
    font-size: inherit;
    font-weight: normal;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
}

button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* ==========================================================================
   Skeleton
   ========================================================================== */

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 14px;
    background-color: #ffffff;
    color: #000;

    /*
    background-image: url(../images/pattern.png);
    background-repeat: repeat;
    background-position: 0 0;

    .tablet ({
        background-image: url(../images/pattern_regular@2x.png);
        background-size: contain;
    });
    .mobile ({
        background-image: url(../images/pattern_regular@2x.png);
        background-size: contain;
    });
    .retina ({
        background-image: url(../images/pattern_regular@2x.png);
        background-size: contain;
    });
    */
}

.center {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    height: 100%;
}

.logo {
    font-size: 0;
    line-height: 0;
    display: inline-block;
    margin: 5px 0;
}
.icon-logo {
    display: inline-block;
    fill: #fff;
    width: 55px;
    height: 50px;

    .tablet ({
        width: 45px;
        height: 40px;
    });
    .mobile ({
        width: 35px;
        height: 30px;
    });
}

.container-desktop {
    .desktop ({
        display: block;
    });
    .tablet ({
        display: none;
    });
    .mobile ({
        display: none;
    });
}
.container-mobile {
    font-size: 14px;

    .desktop ({
        display: none;
    });
    .tablet ({
        display: block;
    });
    .mobile ({
        display: block;
    });
}
.content {
    padding: 80px 0;

    .tablet ({
        padding: 70px 0;
    });
    .mobile ({
        padding: 60px 0;
    });
}

/* ==========================================================================
   Links
   ========================================================================== */

.link {
    background: none;
    border: none;
    border-spacing: 0;
    font-size: inherit;
    font-weight: normal;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
}

/* ==========================================================================
   Page property
   ========================================================================== */

.page-main {}
.page-detail {
    #fp-nav {
        display: none;
    }
}
.page-about {}

/* ==========================================================================
   Header
   ========================================================================== */

.header {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    /*
    .desktop ({
        left: 50%;
        transform: translate(-50%,0);
    });
    */
    .tablet ({
        height: 70px;
    });
    .mobile ({
        height: 60px;
    });
}
.header-line {
    width: 100%;
    height: 60px;
    background-color: @azure;
//    background-image: url(../images/header_bg.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;

    .tablet ({
        background-image: none;
        height: 50px;
    });
    .mobile ({
        background-image: none;
        height: 40px;
    });
}
.header-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}
.header-title {
    color: #fff;
}
.header-title h1 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 32px;
    display: inline-block;
    vertical-align: middle;

    .tablet ({
        font-size: 26px;
    });
    .mobile ({
        font-size: 20px;
    });
}
.header-lang {
    color: #fff;
    display: inline-block;
    font-size: 22px;
    font-weight: 100;
    margin-left: 45px;
    text-transform: uppercase;
    vertical-align: middle;

    transition: all 0.3s ease-in-out;

    .tablet ({
        font-size: 18px;
    });
    .mobile ({
        font-size: 14px;
    });
}

/* ==========================================================================
   Grid
   ========================================================================== */

.grid {
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    margin: 0 auto;

    .img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

.grid-main {
    width: 1870px;
    height: 1195px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;

    .cols {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .cells {
        display: flex;
        justify-content: space-between;
    }
    .cell {
        display: inline-block;
        vertical-align: top;
        position: relative;
        border-radius: 15px;
        overflow: hidden;
    }
    .cell-empty {
        background: rgba(224,250,252,0.6);
    }
    .cell-empty:before {
        content: "";
        display: block;
        position: absolute;
        width: 30%;
        height: 30%;
        background-image: url(../images/logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
    }
}
.cell-1 {
    width: 720px;
    height: 385px;
}
.cell-2 {
    width: 1130px;
    height: 385px;
}
.cell-3 {
    width: 1390px;
    height: 385px;
}
.cell-4 {
    width: 685px;
    height: 385px;
}
.cell-5 {
    width: 685px;
    height: 385px;
}
.cell-6 {
    width: 460px;
    height: 790px;
}

.grid-detail {
    width: 1830px;
    height: 1130px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .cols {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: space-between;
    }
    .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .cell {
        display: inline-block;
        vertical-align: top;
        position: relative;
        border-radius: 15px;
        overflow: hidden;
    }
    .cell-detail {
        background: rgba(224,250,252,0.6);
    }
}
.cell-detail-1 {
    width: 1045px;
    height: 585px;
}
.cell-detail-2 {
    width: 1045px;
    height: 520px;
}
.cell-detail-3 {
    width: 372px;
    height: 480px;
}
.cell-detail-4 {
    width: 372px;
    height: 480px;
}
.cell-detail-5 {
    width: 765px;
    height: 630px;
}


/* ==========================================================================
   Common
   ========================================================================== */

.detail-video {
    position: relative;
    cursor: pointer;
}
.detail-video.active {
    .detail-video-icon,
    .detail-video-image {
        opacity: 0;
        display: none;
        z-index: -1;
    }
    .detail-video-frame {
        opacity: 1;
        position: relative;
        z-index: 1;
    }
}
.detail-video-icon {
    opacity: 1;
    display: block;

    /*
    .retina ({
        display: none;
    });
    */
    .tablet ({
        display: none;
    });
    .mobile ({
        display: none;
    });
}
.icon-video {
    display: inline-block;
    position: absolute;
    fill: #fff;
    opacity: 0.8;
    width: 20%;
    height: 20%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.detail-video-image {
    font-size: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    z-index: 1;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    /*
    .retina ({
        display: none;
    });
    */
    .tablet ({
        display: none;
    });
    .mobile ({
        display: none;
    });
}
.ipad,
.tablet {
    .detail-video-image {
        display: none;
    }
}

.detail-video-frame-hide {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    z-index: auto;
    opacity: 1;
    height: auto;
    width: 100%;
}
.detail-video-frame {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    .tablet ({
        .detail-video-frame-hide;
    });
    .mobile ({
        .detail-video-frame-hide;
    });
    /*
    .retina ({
        .detail-video-frame-hide;
    });
    */

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
.ipad,
.tablet {
    .detail-video-frame {
        .detail-video-frame-hide;
    }
}
.detail-data {
    background: #6cceff;
    background: #F2F2F2;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    color: #333333;
    padding: 5%;

    .data-content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    .data-title {
        font-weight: 700;
        text-transform: uppercase;

        font-size: 2.6vh;
        line-height: 3.8vh;
        margin-bottom: 1.8vh;
    }
    .data-text {
        font-size: 2.2vh;
        line-height: 2.2vh;
        margin-bottom: 3vh;
    }
    .data-info {
        font-size: 0;
    }
    .info-item {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 2vh;
        line-height: 2vh;
        margin-bottom: 0.5vh;
        padding-right: 5%;
    }
    .item-title {
        display: inline;
    }
    .item-content {
        display: inline;
    }
}

.project-list {}
.project-item {
    display: block;
    width: 100%;
    height: 300px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    border-radius: 15px;
    overflow: hidden;

    margin-bottom: 15px;

    .tablet ({
        height: 25vw;
    });
    .mobile ({
        height: 40vw;
    });
}
.project-item:last-child {
    margin-bottom: 0;
}
.project-video {
    position: relative;
    cursor: pointer;
    margin-bottom: 2vw;
    border-radius: 15px;
    overflow: hidden;

    .video-frame {
        width: 100%;
        height: auto;
        padding-bottom: 56.25%;
    }
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.container-mobile {
    .project-video .video iframe {
        width: 100%;
        height: 100%;
    }
}
.project-data {
    background: #6cceff;
    background: #F2F2F2;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    color: #333333;
    padding: 4%;
    margin-bottom: 2vw;
    border-radius: 15px;

    .data-content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-right: 5%;
    }
    .data-title {
        font-weight: 700;
        font-size: 4vw;
        line-height: 3.8vw;
        margin-bottom: 2vw;

        .mobile ({
            font-size: 18px;
            font-size: 18px;
            line-height: 20px;
        });
    }
    .data-text {
        font-size: 2.5vw;
        line-height: 2.5vw;
        margin-bottom: 3vw;

        .mobile ({
            font-size: 14px;
            line-height: 16px;
        });
    }
    .data-info {
        font-size: 0;
    }
    .info-item {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 2vw;
        line-height: 2vw;
        margin-bottom: 0.5vh;
        padding-right: 5%;

        .mobile ({
            font-size: 14px;
            line-height: 16px;
        });
    }
    .item-title {
        display: inline;
    }
    .item-content {
        display: inline;
    }
}
.project-images {
    .images-grid {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
    }
    .img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
    .images-row {
        margin-bottom: 2vw;
    }
    .images-row:last-child {
        margin-bottom: 0;
    }
    .images-cols {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: space-between;
    }
    .images-col {
        width: 50%;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .images-col:first-child {
        padding-left: 0;
    }
    .images-col:last-child {
        padding-right: 0;
    }
    .images-col .images-cell {
        width: 100%;
        height: 60vw;
    }
    .images-row .images-cell {
        width: 100%;
        height: 60vw;
    }
    .images-cell {
        border-radius: 15px;
        overflow: hidden;
    }
}

.about {
    position: relative;
    width: 75vw;
    height: 98vh;
    padding: 80px 0;
    margin: 0 auto;

    .tablet ({
        padding: 70px 0;
        width: 100%;
        height: auto;
    });
    .mobile ({
        padding: 60px 0;
        width: 100%;
        height: auto;
    });
}
.about-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .tablet ({
        width: auto;
        height: auto;
    });
    .mobile ({
        width: auto;
        height: auto;
    });
}
.about-hero {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;

    .tablet ({
        display: none;
    });
    .mobile ({
        display: none;
    });
}
.hero-parovozov {
    width: 635px;
    height: 591px;

    width: 25%;
    height: 50%;
    background-position: 0 0;
    background-image: url(../images/hero/parovozov.png);
    top: 0;
    left: 0;
}
.hero-hare {
    width: 118px;
    height: 186px;
    background-image: url(../images/hero/hare.png);
    left: 50%;
    top: 3%;
    transform: translate(-60%,0);
    z-index: 0;

    width: 5%;
    height: 15%;
}
.hero-bird {
    width: 240px;
    height: 240px;
    background-image: url(../images/hero/bird.png);
    right: 10%;
    top: -1%;
    z-index: 3;

    width: 9%;
    height: 20%;
}
.hero-bear {
    width: 488px;
    height: 530px;
    background-image: url(../images/hero/bear.png);
    right: 5%;
    top: 50%;
    z-index: 0;
    transform: translate(0,-60%);

    width: 15%;
    height: 30%;
}
.hero-kids {
    width: 467px;
    height: 445px;
    background-position: 100% 100%;
    background-image: url(../images/hero/kids.png);
    right: 5%;
    bottom: -2%;
    z-index: 5;

    width: 15%;
    height: 30%;
}
.hero-elk {
    width: 462px;
    height: 787px;
    background-position: 0 100%;
    background-image: url(../images/hero/elk.png);
    bottom: -5%;
    left: 4%;
    z-index: 7;

    width: 15%;
    height: 55%;
}
.about-data {
    background-color: #6cceff;
    width: 70%;
    height: 70%;
    position: relative;
    z-index: 100;
    margin: 0 auto;
    border-radius: 15px;
    z-index: 1;
    border: 10px solid #6cceff;
    overflow-y: auto;
    color: #fff;

    .tablet ({
        width: 95%;
        height: auto;
        border-width: 10px;
    });
    .mobile ({
        width: 95%;
        height: auto;
        border-width: 10px;
    });

    &:after {
        content: "";
        background-image: url(../images/hero/elk-rtl.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 100%;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20%;
        height: 35%;
        height: 35%;
        display: none;

        .tablet ({
            display: block;
        });
        .mobile ({
            display: block;
        });
    }
    .data-content {
        margin: 20px;
        padding: 20px;

        .tablet ({
            padding: 20px;
        });
        .mobile ({
            padding: 0;
        });
    }
    .data-text {
        margin-bottom: 4vw;
        font-size: 1.5vw;

        .tablet ({
            font-size: 3vw;
        });
        .mobile ({
            font-size: 16px;
        });
    }
    .data-contacts {
        font-size: 1.5vw;

        .tablet ({
            font-size: 3vw;
        });
        .mobile ({
            font-size: 16px;
        });
    }

    .contacts-title {
        margin-bottom: 1vw;
    }
    .contacts-content {
        display: table;
        width: 100%;

        .mobile ({
            width: auto;
            display: block;
        });
    }
    .contacts-address {
        display: table-cell;
        vertical-align: top;
        width: 50%;

        .mobile ({
            margin-bottom: 1vw;
            width: auto;
            display: block;
        });
    }
    .contacts-call {
        display: table-cell;
        vertical-align: top;
        width: 50%;
    }
    .contacts-phone {}
    .contacts-email a {
        color: #0D3F7A;
    }
    .contacts-email a:hover {
        color: darken(#0D3F7A,10%);
    }
}

/* ==========================================================================
   Footer
   ========================================================================== */

.footer {
    width: 100%;
    height: 80px;
    padding-top: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;

    /*
    .desktop ({
        left: 50%;
        transform: translate(-50%,0);
    });
    */
    .tablet ({
        height: 70px;
    });
    .mobile ({
        height: 60px;
    });
}
.footer-line {
    overflow: hidden;
    height: 60px;
    background-color: @azure;
//    background-image: url(../images/footer_bg.png);
    background-position: 100% 0px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;

    .tablet ({
        background-image: none;
        height: 50px;
    });
    .mobile ({
        background-image: none;
        height: 40px;
    });
}
.footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}
.footer-logo {
    display: inline-block;
    vertical-align: middle;
    padding-right: 60px;

    .tablet ({
        padding-right: 40px;
    });
    .mobile ({
        padding-right: 20px;
    });
}
.footer-links {
    display: inline-block;
    vertical-align: middle;
}
.footer-contacts {
    text-align: right;
}
.link-about {
    font-size: 18px;
    color: #fff;

    .tablet ({
        font-size: 16px;
    });
    .mobile ({
        font-size: 14px;
    });
}
.contacts {
    font-size: 16px;

    .tablet ({
        font-size: 14px;
    });
    .mobile ({
        font-size: 12px;
    });
}
.contacts-phone {
    color: #fff;
}
.contacts-email a {
    color: #fff;
    font-weight: 700;
}

/* ==========================================================================
   Plugins
   ========================================================================== */

#fp-nav,
.fp-slidesNav {
    ul li a span {
        background: #08A0AD;
    }
}
